import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import {
  createMultipleAnswers,
  createQuestion,
  getCityByUserId,
  getCurrentUser,
  getQuestionById, updateQuestion
} from "../../service/service";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import {setCity} from "../../utils/redux/slices/citySlice";
import {useDispatch, useSelector} from "react-redux";

const QuestionForm = () => {
  let { id } = useParams();
  const isAddMode = !id;
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const city = useSelector(state => state.city.value);

  const handleFormSubmit = async (values) => {
    if (!isAddMode) {
      await updateQuestionAndAnswers(values, id);
      console.log(values);
    } else {
      await createQuestionAndAnswers(values);
    }
    navigate(-1);
  };

  useEffect(() => {
    if (!isAddMode) {
      const fetchData = async () => {
        let found = await getQuestionById(id);
        console.log(found?.data);
        if (found) {
          console.log(found.data['questionText'])
          await formik.setFieldValue("questionText", found.data["questionText"]);
          console.log(found?.data.answers)
          if(found?.data?.answers[0].answerText != "Da" && found?.data?.answers[0].answerText != "Ne") {
            formik.setFieldValue("yesNoAnswer", false)
            let answerOptions = found.data.answers.map((a) => {
              return {...a, value:a.answerText, id:a.answerId}
            })
            await formik.setFieldValue("answerOptions", answerOptions)
          }
        }
      }
      fetchData()
          .catch(console.error);
    }
  },[]);

  const createQuestionAndAnswers = async (values) => {
    let questionData= {
      cityId:city,
      questionText:values.questionText,
    }
    let questionResponse = await createQuestion(questionData);
    if(values.yesNoAnswer) {
      let answersDate = [{
        answerText:"Da",
        questionId:questionResponse.data.questionId,
      },
      {
          answerText:"Ne",
          questionId:questionResponse.data.questionId,
      }];
      let answersResponse = await createMultipleAnswers(answersDate);
      console.log(answersResponse)
    } else {
      let answersRequest = values.answerOptions.map(({ id, value, questionText }) => ({
        answerText: value,          // Change key id to newId
        questionId: questionResponse.data.questionId,     // Change key value to newValue
      }));
      let answersResponse = await createMultipleAnswers(answersRequest);
      console.log(answersResponse)
    }

  }

  const updateQuestionAndAnswers = async (values, id) => {
    let answers = values.answerOptions.map(({ id, value, questionText, answerId }) => ({
      answerText: value,          // Change key id to newId
      questionId: id,     // Change key value to newValue
      answerId: answerId,     // Change key value to newValue
    }));

    if(values.yesNoAnswer && (values.answerOptions.filter(e => e.answerText === 'Da').length <= 0 && values.answerOptions.filter(e => e.answerText === 'Ne').length <= 0)) {
      answers = [{
        answerText:"Da",
        questionId: id,
      },
        {
          answerText:"Ne",
          questionId: id,
        }];
    }
    let questionData= {
      cityId:city,
      questionText:values.questionText,
      answers:answers
    }
    console.log(questionData);
    let questionResponse = await updateQuestion(questionData, id);
  }

  const handleAddAnswerOption = () => {
    formik.setFieldValue("answerOptions", [
      ...formik.values.answerOptions,
      { id: Date.now(), value: "" },
    ]);
  };

  const handleDeleteAnswerOption = (id) => {
    formik.setFieldValue(
        "answerOptions",
        formik.values.answerOptions.filter((option) => option.id !== id)
    );
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: checkoutSchema,
    onSubmit: handleFormSubmit,
  });

  useEffect(() => {
    const fetchCities = async () => {
      let citiesResponse = await getCurrentUser();
      let city = await getCityByUserId(citiesResponse.data.id);
      dispatch(setCity(city.data.id));
    }
    fetchCities()
        .catch(console.error);
  }, []);

  useEffect(() => {
    // Automatically add one answer option when yesNoAnswer is initially true
    if (formik.values.yesNoAnswer && formik.values.answerOptions.length === 0) {
      handleAddAnswerOption();
    }
  }, [formik.values.yesNoAnswer]); // Run this effect when yesNoAnswer changes

  return (
      <Box m="20px">
        <Header
            title={isAddMode ? "KREIRAJ PITANJE" : "UREDI PITANJE"}
            subtitle={isAddMode ? "Kreiraj novo pitanje" : "Uredi postojece pitanje"}
        />
        <form onSubmit={formik.handleSubmit}>
          <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
          >
            <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Pitanje"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.questionText}
                name="questionText"
                error={!!formik.touched.questionText && !!formik.errors.questionText}
                helperText={formik.touched.questionText && formik.errors.questionText}
                sx={{ gridColumn: "span 4" }}
            />
            <FormControlLabel
                control={
                  <Checkbox
                      checked={formik.values.yesNoAnswer}
                      onChange={(e) => formik.setFieldValue("yesNoAnswer", e.target.checked)}
                      name="yesNoAnswer"
                      color="secondary"
                  />
                }
                label="Da/Ne Odgovor"
            />
            {/* Conditionally render an answer option if yesNoAnswer is true */}
            {!formik.values.yesNoAnswer &&
                formik.values.answerOptions.map((option, index) => (
                    <Box key={option.id} sx={{ gridColumn: "span 4", display: "flex", alignItems: "center" }}>
                      <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={`Odgovor br. ${index + 1}`}
                          onBlur={formik.handleBlur}
                          onChange={(e) => {
                            const updatedOptions = [...formik.values.answerOptions];
                            updatedOptions[index].value = e.target.value;
                            formik.setFieldValue("answerOptions", updatedOptions);
                          }}
                          value={option.value}
                          name={`answerOptions.${index}.value`}
                          error={!!formik.touched.answerOptions?.[index]?.value && !!formik.errors.answerOptions?.[index]?.value}
                          helperText={formik.touched.answerOptions?.[index]?.value && formik.errors.answerOptions?.[index]?.value}
                      />
                      <Button onClick={() => handleDeleteAnswerOption(option.id)} sx={{ marginLeft: 2 }} color="secondary" variant="contained">
                        Ukloni
                      </Button>
                    </Box>
                ))}
            {/* Show "Add Answer" button only when yesNoAnswer is false */}
            {!formik.values.yesNoAnswer && (
                <Fab onClick={handleAddAnswerOption} color="primary" sx={{ gridColumn: "span 4", marginTop: 2 }}>
                  <AddIcon />
                </Fab>
            )}
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button sx={{ mr: 2 }} onClick={() => navigate(-1)} color="secondary" variant="contained">
              Odustani
            </Button>
            <Button type="submit" color="secondary" variant="contained">
              {isAddMode ? "Kreiraj novo pitanje" : "Uredi pitanje"}
            </Button>
          </Box>
        </form>
      </Box>
  );
};

const checkoutSchema = yup.object().shape({
  questionText: yup.string().required("required"),
  yesNoAnswer: yup.bool(),
  answerOptions: yup.array().of(
      yup.object().shape({
        value: yup.string().when("yesNoAnswer", {
          is: false,
          then: yup.string().required("required for dynamic answer options"),
        }),
      })
  ),
});

const initialValues = {
  questionText: "",
  yesNoAnswer: true,
  answerOptions: [],
};

export default QuestionForm;
