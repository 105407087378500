import React, { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { deleteQuestion, getCities, getQuestionsStatsByCityId } from '../../service/service';

const AdminQuestions = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [data, setData] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {
        const initializeData = async () => {
            const citiesResponse = await getCities();
            const citiesData = citiesResponse?.data?.content || [];
            setCities(citiesData);
            if (citiesData.length > 0) {
                setSelectedCity(citiesData[0].id);
                fetchQuestionsData(citiesData[0].id);
            }
        };
        initializeData();
    }, []);

    const fetchQuestionsData = async (cityId) => {
        const questionsData = await getQuestionsStatsByCityId(cityId);
        processAndSetData(questionsData);
    };

    const processAndSetData = (data) => {
        const flattenedArray = data?.data?.map((item) => {
            const { questionId, questionText, answers } = item;
            const generateId = (parentId, index) => `${parentId}_${index}`;

            // Flatten each question with its answers
            const flattenedItem = [
                { id: generateId(questionId, 0), questionId, questionText, answers: [] }, // Question without answers
                ...answers.map((answer, index) => ({ ...answer, id: generateId(questionId, index + 1), parentQuestionId: questionId })), // Answers with a reference to the parent question
            ];

            return flattenedItem;
        });

        // Merge the flattened arrays into a single array
        const resultArray = [].concat(...flattenedArray);
        setData(resultArray);
    };

    const handleCityChange = (event) => {
        const newCityId = event.target.value;
        setSelectedCity(newCityId);
        fetchQuestionsData(newCityId);
    };

    const columns = [
        {
            field: "questionText",
            headerName: "Pitanje",
            headerAlign: "left",
            align: "left",
            flex: 1
        },
        {
            field: "answerText",
            headerName: "Odgovor",
            headerAlign: "left",
            align: "left",
            flex: 1
        },
        {
            field: "maleCount",
            headerName: "Muskaraca",
            headerAlign: "left",
            align: "left",
            flex: 0.25
        },
        {
            field: "femaleCount",
            headerName: "Zena",
            headerAlign: "left",
            align: "left",
            flex: 0.25
        },
        {
            field: "averageAge",
            headerName: "Srednja vrijednost godina",
            headerAlign: "left",
            align: "left",
            flex: 0.5,
            valueFormatter: (params) => { return params.value ? Number(params?.value).toFixed(2) : "" }
        },
        {
            field: "totalCount",
            headerName: "Broj Odgovora",
            headerAlign: "left",
            align: "left",
            flex: 0.5
        },
    ];

    const CustomToolbar = () => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <GridToolbar />
            <FormControl variant="standard" sx={{ minWidth: 120, pb:2 }}>
                <InputLabel id="city-select-label">Grad</InputLabel>
                <Select
                    labelId="city-select-label"
                    id="city-select"
                    value={selectedCity}
                    onChange={handleCityChange}
                    label="City"
                >
                    {cities.map(city => (
                        <MenuItem key={city.id} value={city.id}>{city.cityName}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );

    return (
        <Box m="20px">
            <Header
                title="PITANJA"
                subtitle="Lista Pitanja"
            />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                    "& .super-app-theme--Red": {
                        backgroundColor: colors.redAccent[800],
                        '&:hover': {
                            backgroundColor: colors.redAccent[900],
                        }
                    },

                }}
            >
                <DataGrid
                    rows={data}
                    getRowClassName={(params) => {
                        return (`super-app-theme--${params.row.questionText != undefined ?  "Red" : ""}`)
                    }}
                    columns={columns}
                    localeText={{
                        toolbarColumns: "kolone",
                        toolbarFilters: "filteri",
                        toolbarDensity: "gustina",
                        toolbarExport: "export"
                    }}
                    components={{Toolbar: CustomToolbar}}
                />
            </Box>
        </Box>
    );
};

export default AdminQuestions;
