import {Box, Typography, useTheme} from "@mui/material";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import {tokens} from "../../theme";
import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useNavigate} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import {
    deleteQuestion, getCityByUserId, getCurrentUser,
    getQuestionsStatsByCityId
} from "../../service/service";

const Questions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [data, setData] = useState([]);


    const handleClickOpen = (value) => {
    setOpen(true);
    setDeleteIndex(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await deleteQuestion(deleteIndex);
    setDeleteIndex(null);
    handleClose()
  };


  useEffect(()=> {
    const fetchData = async () => {
        let citiesResponse = await getCurrentUser();
        let city = await getCityByUserId(citiesResponse.data.id);
        const data = await getQuestionsStatsByCityId(city.data.id);
        const flattenedArray = data?.data?.map((item) => {
        const { questionId, questionText, answers } = item;
      const generateId = (parentId, index) => `${parentId}_${index}`;

          // Flatten each question with its answers
          const flattenedItem = [
              { id: generateId(questionId, 0), questionId, questionText, answers: [] }, // Question without answers
              ...answers.map((answer, index) => ({ ...answer, id: generateId(questionId, index + 1), parentQuestionId: questionId })), // Answers with a reference to the parent question
          ];

            return flattenedItem;
        });

        // Merge the flattened arrays into a single array
        const resultArray = [].concat(...flattenedArray);
        setData(resultArray);


    }
    fetchData()
      .catch(console.error)

  },[deleteIndex === null ? null : deleteIndex]);

  const columns = [
    {
      field: "action",
      headerName: "Akcije",
      flex: 0.33,
      minWidth: 40,
      renderCell: (params) => {

       if(params.row.questionText) { return (<Box display="flex">
          <IconButton onClick={() => navigate(`/questions/${params.row.questionId}`)}>
            <EditIcon></EditIcon>
          </IconButton>
          <IconButton onClick={()=> handleClickOpen(params.row.questionId)}>
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </Box>) }
      }

    },
    {
      field: "questionText",
      headerName: "Pitanje",
      headerAlign: "left",
      align: "left",
      flex: 1
    },
    {
      field: "answerText",
      headerName: "Odgovor",
      headerAlign: "left",
      align: "left",
      flex: 1
    },
    {
      field: "maleCount",
      headerName: "Muskaraca",
      headerAlign: "left",
      align: "left",
      flex: 0.25
    },
    {
      field: "femaleCount",
      headerName: "Zena",
      headerAlign: "left",
      align: "left",
      flex: 0.25
    },
    {
      field: "averageAge",
      headerName: "Srednja vrijednost godina",
      headerAlign: "left",
      align: "left",
      flex: 0.5,
      valueFormatter: (params) => { return params.value ? Number(params?.value).toFixed(2) : "" }
    },
    {
      field: "totalCount",
      headerName: "Broj Odgovora",
      headerAlign: "left",
      align: "left",
      flex: 0.5
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="PITANJA"
        subtitle="Lista Pitanja"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .super-app-theme--Red": {
            backgroundColor: colors.redAccent[800],
            '&:hover': {
              backgroundColor: colors.redAccent[900],
            }
          },

        }}
      >
        <DataGrid
          rows={data}
          getRowClassName={(params) => {
            return (`super-app-theme--${params.row.questionText != undefined ?  "Red" : ""}`)
          }}
          columns={columns}
          localeText={{
              toolbarColumns: "kolone",
              toolbarFilters: "filteri",
              toolbarDensity: "gustina",
              toolbarExport: "export"
          }}
          components={{Toolbar: GridToolbar}}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete selected user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Disagree</Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Questions;
