import {Avatar, Box, TextField, Typography, Link, Button, MenuItem, Select, InputLabel} from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {Formik} from 'formik';
import * as yup from "yup";
import secureLocalStorage from "react-secure-storage";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import {getCities, getCityByUserId, getCurrentUser, register} from "../../service/service";
import {setToken} from "../../utils/redux/slices/tokenSlice";
import {setCity} from "../../utils/redux/slices/citySlice";

const RegisterForm = () => {
    const [cities, setCities] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchCities = async () => {
            let citiesResponse = await getCities();
            setCities(citiesResponse.data.content);
            console.log(citiesResponse)
        }
        fetchCities()
            .catch(console.error);
    }, []);

    const handleFormSubmit = async (values) => {
        console.log(values);
        try {
            let token = await register(values);
            await secureLocalStorage.setItem("token", token.data.token);
            let citiesResponse = await getCurrentUser();
            let city = await getCityByUserId(citiesResponse.data.id);
            dispatch(setCity(city.data.id));
            dispatch(setToken(token.data.token));
            navigate('/questions');
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                <PersonAddIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Registruj se
            </Typography>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={registerSchema}
                enableReinitialize={true}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => {
                    return (
                        <Box component="form" onSubmit={handleSubmit} sx={{minWidth: '40%'}}>
                            <Box
                                display="flex"
                                gap="30px"
                                justifyItems='center'
                                flexDirection="column"
                            >
                                <TextField
                                    variant="filled"
                                    label="Ime"
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.fistname}
                                    name="fistname"
                                    error={!!touched.fistname && !!errors.fistname}
                                    helperText={touched.fistname && errors.fistname}
                                    fullWidth
                                />
                                <TextField
                                    variant="filled"
                                    label="Prezime"
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastname}
                                    name="lastname"
                                    error={!!touched.lastname && !!errors.lastname}
                                    helperText={touched.lastname && errors.lastname}
                                    fullWidth
                                />
                                <TextField
                                    variant="filled"
                                    label="E-mail"
                                    type="text"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    name="email"
                                    error={!!touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                    fullWidth
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    label="Lozinka"
                                    type="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={!!touched.password && !!errors.password}
                                    helperText={touched.password && errors.password}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    label="Potvrdi lozinku"
                                    type="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    name="confirmPassword"
                                    error={!!touched.confirmPassword && !!errors.confirmPassword}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                />
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    select
                                    label="Odaberi Grad"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.cityId}
                                    name="cityId"
                                    error={!!touched.cityId && !!errors.cityId}
                                    helperText={touched.cityId && errors.cityId}
                                    sx={{gridColumn: "span 4"}}
                                >
                                    <MenuItem value={""}>
                                        Nije izabrano
                                    </MenuItem>
                                    {cities.map((option) => (
                                        <MenuItem value={option.id}>
                                            {option.cityName}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Registruj se
                                </Button>
                                <Typography>
                                    Imate nalog? <Link href="/login" color="secondary">Pristupi.</Link>
                                </Typography>
                            </Box>
                        </Box>
                    );
                }}
            </Formik>
        </Box>
    );
};

const registerSchema = yup.object().shape({
    fistname: yup.string().required("obavezno"),
    lastname: yup.string().required("obavezno"),
    email: yup.string().required("obavezno"),
    password: yup.string().required("obavezno"),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Lozinke se moraju podudarati').required('Potvrda lozinke je obavezna'),
    cityId: yup.number().required("obavezno"),
});

const initialValues = {
    fistname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    cityId: "",
};

export default RegisterForm;
