import {configureStore} from '@reduxjs/toolkit';
import tokenSliceReducer from './slices/tokenSlice';
import snackbarSliceReducer from "./slices/snackbarSlice";
import citySliceReducer from "./slices/citySlice";
import adminSliceReducer from "./slices/adminSlice";

export const store = configureStore({
  reducer: {
    token: tokenSliceReducer,
    snackbar: snackbarSliceReducer,
    city: citySliceReducer,
    admin: adminSliceReducer
  }
});
