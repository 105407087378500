import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  value: '',
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.value = action.payload;
    },
    clearToken: (state, action) => {
      return initialState;
    },
  },
});

export const {setToken, clearToken} = tokenSlice.actions;

export default tokenSlice.reducer;
