import axiosInstance from "./axios";
const baseURL = 'http://161.35.26.195:8082';

export async function getDrivers() {
  let data =  axiosInstance.get(`${baseURL}/drivers/search`);
return data
}

export async function getCurrentUser() {
  let data =  axiosInstance.get(`${baseURL}/auth/current-user`);
return data
}

export async function createQuestion(data) {
 return  axiosInstance.post(`${baseURL}/questions`, data);
}

export async function createAnswer(data) {
  axiosInstance.post(`${baseURL}/answers`, data);
  return data

}
export async function createMultipleAnswers(data) {
  axiosInstance.post(`${baseURL}/answers/create-multiple`, data);
  return data

}
export async function getCityByUserId(userId) {
  let data =  axiosInstance.get(`${baseURL}/cities/user/${userId}`);
return data
}
export async function getCities() {
  let data =  axiosInstance.get(`${baseURL}/cities`);
return data
}

export async function getDriversDetails() {
  let data =  axiosInstance.get(`${baseURL}/driver-details`);
  return data
}


export async function getTruckById(truckId) {
  let data =  axiosInstance.get(`${baseURL}/trucks/${truckId}`);
  return data
}

export async function getTrucks() {
  let data = axiosInstance.get(`${baseURL}/trucks/search`);
  return data

}

export async function getTrucksDetails() {
  let data =  axiosInstance.get(`${baseURL}/truck-details`);
  return data
}

export async function getDriverById(driverId) {
  let data =  axiosInstance.get(`${baseURL}/drivers/${driverId}`);
  return data
}


export async function createDriver(data) {
  axiosInstance.post(`${baseURL}/drivers", data`);
  return data

}

export async function updateDriver(data, driverId) {
  axiosInstance.put(`${baseURL}/drivers/${driverId}`, data);
  return data

}

export async function createTruck(data) {
  axiosInstance.post(`${baseURL}/trucks`, data);
  return data

}

export async function updateTruck(data, truckId) {
  axiosInstance.put(`${baseURL}/trucks/${truckId}`, data);
  return data

}

export async function createIncome(data) {
  axiosInstance.post(`${baseURL}/incomes`, data);
  return data

}

export async function updateIncome(data, incomeId) {
  axiosInstance.put(`${baseURL}/incomes/${incomeId}`, data);
  return data

}

export async function getIncomeById(truckId) {
  let data =  axiosInstance.get(`${baseURL}/incomes/${truckId}`);
  return data
}

export async function getQuestionById(questionId) {
  let data = await axiosInstance.get(`${baseURL}/questions/${questionId}`);
  return data
}
export async function getExpenseById(expenseId) {
  let data =  axiosInstance.get(`${baseURL}/expenses/${expenseId}`);
  return data
}


export async function createExpense(data) {
  axiosInstance.post(`${baseURL}/expenses`, data);
  return data

}

export async function updateExpense(data, expenseId) {
  axiosInstance.put(`${baseURL}/drivers/${expenseId}`, data);
}

export async function getInspectionById(inspectionId) {
  return axiosInstance.get(`${baseURL}/inspections/${inspectionId}`)
}

export async function getQuestionsByCityId(cityId) {
  return axiosInstance.get(`${baseURL}/questions/city/${cityId}`)
}
export async function getQuestionsStatsByCityId(cityId) {
  return axiosInstance.get(`${baseURL}/questions/stats/city/${cityId}`)
}


export async function createInspection(data) {
  await axiosInstance.post(`${baseURL}/inspections`, data);

}

export async function register(data) {
  return await axiosInstance.post(`${baseURL}/auth/register`, data);

}

export async function updateInspection(data, inspectionId) {
  await axiosInstance.put(`${baseURL}/inspections/${inspectionId}`, data);
}

export async function getInspections() {
  return axiosInstance.get(`${baseURL}/inspections/search`)
}

export async function getExpenses() {
  return axiosInstance.get(`${baseURL}/expenses/search`)
}

export async function getIncomes() {
  return axiosInstance.get(`${baseURL}/incomes/search`)
}

export async function login(userData) {
  return axiosInstance.post(`${baseURL}/auth/authenticate`, userData)
}

export async function getTrailers() {
  return axiosInstance.get(`${baseURL}/trailers/search`)
}

  export async function getTrailerById(trailerId) {
    return axiosInstance.get(`${baseURL}/trailers/${trailerId}`)
  }


  export async function createTrailer(data) {
    await axiosInstance.post(`${baseURL}/trailers`, data);

  }

  export async function updateQuestion(data, questionId) {
    await axiosInstance.put(`${baseURL}/questions/${questionId}`, data);
}

export async function deleteTrailer(trailerId) {
  await axiosInstance.delete(`${baseURL}/trailers/${trailerId}`);
}
export async function deleteTruck(truckId) {
  await axiosInstance.delete(`${baseURL}/trucks/${truckId}`);
}
export async function deleteQuestion(driverId) {
  await axiosInstance.delete(`${baseURL}/questions/${driverId}`);
}
export async function deleteInspection(inspectionId) {
  await axiosInstance.delete(`${baseURL}/inspections/${inspectionId}`);
}
export async function deleteIncome(incomeId) {
  await axiosInstance.delete(`${baseURL}/incomes/${incomeId}`);
}
export async function deleteExpense(expenseId) {
  await axiosInstance.delete(`${baseURL}/expenses/${expenseId}`);
}



