import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Header from "../../components/Header";
import BarChart from "../../components/BarChart";
import {useEffect, useState} from "react";
import {getCities} from "../../service/service";

const AdminBar = () => {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(0);

    useEffect(() => {
        const fetchCities = async () => {
            const response = await getCities();
            setCities(response.data.content); // Adapt based on your actual API response structure
            if (response.data.length > 0) {
                setSelectedCity(response.data[0].id); // Default to first city
            }
        };
        fetchCities();
    }, []);

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    return (
        <Box m="20px">
            <Header title="Statistika" subtitle="Graficki prikaz" />
            <FormControl fullWidth>
                <InputLabel id="city-selector-label">City</InputLabel>
                <Select
                    labelId="city-selector-label"
                    id="city-selector"
                    value={selectedCity}
                    label="City"
                    onChange={handleCityChange}
                >
                    {cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                            {city.cityName} {/* Adjust if your city object structure is different */}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
                <Box height="65vh">
                    <BarChart cityId={selectedCity} />
                </Box>
        </Box>
    );
};

export default AdminBar;
