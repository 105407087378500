import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  value: '',
};

export const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    setCity: (state, action) => {
      state.value = action.payload;
    },
    clearCity: (state, action) => {
      return initialState;
    },
  },
});

export const {setCity, clearCity} = citySlice.actions;

export default citySlice.reducer;
