import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    message: '',
    severity: 'info',
    open: false,
  },
  reducers: {
    showSnackbar: (state, action) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.open = true;
    },
    closeSnackbar: (state) => {
      state.open = false;
    },
  },
});

export const { showSnackbar, closeSnackbar } = snackbarSlice.actions;

export const selectSnackbar = (state) => state.snackbar;

export default snackbarSlice.reducer;
