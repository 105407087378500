import { Avatar, Box, TextField, Typography, Link } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Formik } from 'formik';
import * as yup from "yup";
import Button from "@mui/material/Button";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {getCityByUserId, getCurrentUser, login} from "../../service/service";
import { setToken } from "../../utils/redux/slices/tokenSlice";
import {setCity} from "../../utils/redux/slices/citySlice";
import {setAdmin} from "../../utils/redux/slices/adminSlice";

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = async (values) => {
    try {
      let token = await login(values);
      await secureLocalStorage.setItem("token", token.data.token);
      let citiesResponse = await getCurrentUser();
      if(citiesResponse.data.email === "info@link4cooperation.ba") {
        dispatch(setAdmin(true))
      }
      console.log(citiesResponse.data);
      let city = await getCityByUserId(citiesResponse.data.id);
      dispatch(setCity(city.data.id));
      dispatch(setToken(token.data.token));
      navigate('/questions');
    } catch (error) {
      console.log(error);
    }
  };

  return (
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Pristupi
        </Typography>
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
            enableReinitialize={true}
        >
          {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => {
            return (
                <Box component="form" onSubmit={handleSubmit} sx={{ minWidth: '40%' }}>
                  <Box
                      display="flex"
                      gap="30px"
                      justifyItems='center'
                      flexDirection="column"
                  >
                    <TextField
                        variant="filled"
                        label="E-mail"
                        type="text"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        name="email"
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.firstName}
                        fullWidth
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        label="Lozinka"
                        type="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        name="password"
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                    />
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt="20px">
                    <Typography>
                      Nemate nalog? <Link href="/register" color="secondary">Registruj se.</Link>
                    </Typography>
                    <Button type="submit" color="secondary" variant="contained">
                      Pristupi
                    </Button>
                  </Box>
                </Box>
            );
          }}
        </Formik>
        <Box
            display="flex"
            gap="70"
            justifyItems='center'
            flexDirection="row"
            justifySelf='flex-end'
            marginTop='200px'
            borderRadius="20px"
            backgroundColor="white"
            paddingX="20px"
            paddingY="20px"

        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
                alt="profile-user"
                width="130px"
                height="97px"
                src={`../../assets/Funded_by_the_European_Union.png`}
                style={{ cursor: "pointer", }}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
                alt="profile-user"
                width="130px"
                height="97px"
                src={`../../assets/logo-01.png`}
                style={{ cursor: "pointer", }}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
                alt="profile-user"
                width="130px"
                height="97px"
                src={`../../assets/Logo_LDA_Mostar.png`}
                style={{ cursor: "pointer", }}
            />
          </Box>
        </Box>
      </Box>
  );
};

const checkoutSchema = yup.object().shape({
  email: yup.string().required("obavezno"),
  password: yup.string().required("obavezno"),
});

const initialValues = {
  email: "",
  password: "",
};

export default LoginForm;
