import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    value: '',
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setAdmin: (state, action) => {
            state.value = action.payload;
        },
        clearAdmin: (state, action) => {
            return initialState;
        },
    },
});

export const {setAdmin, clearAdmin} = adminSlice.actions;

export default adminSlice.reducer;
