import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { closeSnackbar } from "../utils/redux/slices/snackbarSlice";

function GlobalSnackbar() {
  const dispatch = useDispatch();
  const { message, severity, open } = useSelector(state => state.snackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default GlobalSnackbar;
