import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import {clearToken, setToken} from "../utils/redux/slices/tokenSlice";
import {useDispatch} from "react-redux";
import {closeSnackbar, showSnackbar} from "../utils/redux/slices/snackbarSlice";
import {store} from "../utils/redux/store";


const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
  }
});


axiosInstance.interceptors.request.use(
  async config => {
    const token = secureLocalStorage.getItem("token");
    if (token) {
      store.dispatch(setToken(token))
      config.headers.Authorization = `Bearer ${token}`
    }
    store.dispatch(showSnackbar({
      message: 'Success',
      severity: 'success'
    }))
    return config;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const {status, data} = error.response;
    if (status === 401) {
      secureLocalStorage.removeItem("token");
      store.dispatch(clearToken());
      store.dispatch(showSnackbar({message: 'You are not authorized. Please login again.', severity: 'error'}));
    } else if (status >= 400 && status < 500) {
      store.dispatch(showSnackbar({
        message: data.message || 'An error occurred. Please try again later.',
        severity: 'error'
      }));
    } else if (status >= 500) {
      store.dispatch(showSnackbar({message: 'An error occurred. Please try again later.', severity: 'error'}));
    }
    return Promise.reject(error);
  }
);

// store.subscribe(() => {
//   const { open } = store.getState().snackbar;
//   if (!open) {
//     // Dispatch the closeSnackbar action when the Snackbar is closed
//     store.dispatch(closeSnackbar());
//   }
// });
export default axiosInstance;
