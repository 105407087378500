import './App.css';
import React, {useEffect} from "react";
import {ColorModeContext, useMode} from "./theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import {Route, Routes, Navigate} from "react-router-dom"
import Questions from "./pages/questions";
import Navbar from "./pages/navbar";
import LoginForm from "./pages/loginForm";
import {useDispatch, useSelector} from "react-redux";
import secureLocalStorage from "react-secure-storage";
import {setToken} from "./utils/redux/slices/tokenSlice";
import GlobalSnackbar from "./global/GlobalSnackbar";
import Sidebar from "./global/Sidebar";
import RegisterForm from "./pages/registration";
import QuestionForm from "./pages/questionsForm";
import Bar from "./pages/bar";
import AdminQuestions from "./pages/adminQuestions";
import AdminBar from "./pages/adminBar";


function App() {
  const [theme, colorMode] = useMode();
  const token = useSelector(state => state.token.value);
  const dispatch = useDispatch();
  const userRole = useSelector(state => state.admin.value);
  console.log(userRole)

  useEffect(() => {
    const getToken = async () => {
      let token = await secureLocalStorage.getItem("token")
      console.log("token", token)
      dispatch(setToken(token));
    }
    getToken().catch(console.error);
  })



  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <div className="app">
          <GlobalSnackbar />
          {token && <Sidebar />}
          <main className="content">
            {!token ? (
                <Routes>
                  <Route path="*" element={<Navigate to="/login" replace />} />
                  <Route path="/login" element={<LoginForm />} />
                  <Route path="/register" element={<RegisterForm />} />
                </Routes>
            ) : (
                <>
                  <Navbar />
                  <Routes>
                    <Route path="*" element={<Navigate to={userRole ? "/admin/questions" : "/questions"} replace />} />
                    <Route path="/questions" element={userRole  ? <AdminQuestions /> : <Questions />} />
                    <Route path="/admin/questions" element={userRole  ? <AdminQuestions /> : <Navigate to="/questions" replace />} />
                    <Route path="/admin/bar" element={userRole ? <AdminBar /> : <Navigate to="/bar" replace />} />
                    <Route path="/add-driver" element={<QuestionForm />} />
                    <Route path="/questions/:id" element={<QuestionForm />} />
                    <Route path="/bar" element={<Bar />} />
                  </Routes>
                </>
            )}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
