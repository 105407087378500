import React, {useContext, useState} from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
  Menu,
} from "@mui/material";
import {
  Search,
  Message,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Add,
  Close,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import FlexBetween from "../../components/FlexBetween";
import {ColorModeContext, tokens} from "../../theme";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {useDispatch, useSelector} from "react-redux";
import {clearToken} from "../../utils/redux/slices/tokenSlice";
import secureLocalStorage from "react-secure-storage";
import {clearAdmin} from "../../utils/redux/slices/adminSlice";

const Navbar = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const colorMode = useContext(ColorModeContext);
  const [selected, setSelected] = React.useState('questions');
  const dispatch = useDispatch();


  const handleChange = (event) => {
    setSelected(event.target.value );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const isOpen = Boolean(anchorEl);
  const isOpenUser = Boolean(anchorElUser);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClickUser = (event) => setAnchorElUser(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleCloseUser = () => setAnchorElUser(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const alt = theme.palette.background.alt;
  const isAdmin = useSelector(state => state.admin.value)

  const handleLogout = async () => {
    await secureLocalStorage.clear();
    dispatch(clearToken());
    dispatch(clearAdmin());

  }


  return (
    <FlexBetween padding="1rem" backgroundColor={alt}>
      <FlexBetween gap="1.75rem">
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
          minWidth={100}
          alignItems="center"
        >

        </Box>
      </FlexBetween>

      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween gap="2rem">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkMode sx={{ fontSize: "25px" }} />
            ) : (
              <LightMode sx={{ color: dark, fontSize: "25px" }} />
            )}
          </IconButton>
          {!isAdmin && <IconButton onClick={handleClick}>
            <Add/>
          </IconButton>}
          <IconButton>
            <PersonOutlinedIcon onClick={handleClickUser} />
          </IconButton>
          <Menu
              anchorEl={anchorElUser}
              open={isOpenUser}
              onClose={handleCloseUser}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <MenuItem onClick={()=>{handleLogout(); handleClose()}}>Napusti</MenuItem>
          </Menu>
          <Menu
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <MenuItem onClick={()=>{navigate("/add-driver"); handleClose()}}>Dodaj pitanje</MenuItem>
          </Menu>
          <FormControl variant="standard" value={"fullName"}>
            <Select
              value={selected}
              onChange={handleChange}
              sx={{
                backgroundColor: colors.primary[400],
                width: "150px",
                borderRadius: "0.25rem",
                p: "0.25rem 1rem",
                "& .MuiSvgIcon-root": {
                  pr: "0.25rem",
                  width: "3rem",
                },
                "& .MuiSelect-select:focus": {
                  backgroundColor: colors.primary[400],
                },
              }}
              input={<InputBase />}
            >
              <MenuItem value ={"questions"} onClick={() => navigate("/questions")}>Pitanja</MenuItem>
            </Select>
          </FormControl>
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={background}
        >
          {/* CLOSE ICON */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <IconButton
              onClick={colorMode.toggleColorMode}
              sx={{ fontSize: "25px" }}
            >
              {theme.palette.mode === "dark" ? (
                <DarkMode sx={{ fontSize: "25px" }} />
              ) : (
                <LightMode sx={{ color: dark, fontSize: "25px" }} />
              )}
            </IconButton>
            <IconButton onClick={handleClick}>
              <Add/>
            </IconButton>
            <IconButton>
              <PersonOutlinedIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={()=>{navigate("/add-driver"); handleClose()}}>Add Driver</MenuItem>
              <MenuItem onClick={()=>{navigate("/add-truck"); handleClose()}}>Add Truck</MenuItem>
              <MenuItem onClick={()=>{navigate("/add-trailer"); handleClose()}}>Add Trailer</MenuItem>
              <MenuItem onClick={()=>{navigate("/add-inspection"); handleClose()}}>Add Inspection</MenuItem>
              <MenuItem onClick={()=>{navigate("/add-expense"); handleClose()}}>Add Expense</MenuItem>
              <MenuItem onClick={()=>{navigate("/add-income"); handleClose()}}>Add Income</MenuItem>
            </Menu>
            <FormControl variant="standard" value={"fullName"}>
              <Select
                value={selected}
                onChange={handleChange}
                sx={{
                  backgroundColor: colors.primary[400],
                  width: "150px",
                  borderRadius: "0.25rem",
                  p: "0.25rem 1rem",
                  "& .MuiSvgIcon-root": {
                    pr: "0.25rem",
                    width: "3rem",
                  },
                  "& .MuiSelect-select:focus": {
                    backgroundColor: colors.primary[400],
                  },
                }}
                input={<InputBase />}
              >
                <MenuItem value ={"questions"} onClick={() => navigate("/questions")}>Driver</MenuItem>
                <MenuItem value ={"trucks"} onClick={() => navigate("/trucks")}>Truck</MenuItem>
                <MenuItem value ={"incomes"}  onClick={() => navigate("/incomes")}>Income</MenuItem>
                <MenuItem value ={"expenses"}  onClick={() => navigate("/expenses")}>Expense</MenuItem>
                <MenuItem value ={"inspections"}  onClick={() => navigate("/inspections")}>Inspection</MenuItem>
                <MenuItem value ={"trailers"} onClick={() => navigate("/trailers")}>Trailer</MenuItem>
              </Select>
            </FormControl>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default Navbar;
