import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import {useEffect, useState} from "react";
import {getCityByUserId, getCurrentUser, getQuestionsStatsByCityId} from "../service/service";

const BarChart = ({ isDashboard = false }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    console.log(data);

    useEffect(()=> {
        const fetchData = async () => {
            let citiesResponse = await getCurrentUser();
            let city = await getCityByUserId(citiesResponse.data.id);
            const data = await getQuestionsStatsByCityId(city.data.id);
            const flattenedArray = data?.data?.map((item) => {
                const { questionId, questionText, answers } = item;
                const generateId = (parentId, index) => `${parentId}_${index}`;

                // Flatten each question with its answers
                const flattenedItem = [
                    ...answers.map((answer, index) => ({ ...answer, id: generateId(questionId, index + 1), parentQuestionId: `${questionText} / ${answer.answerText}` })), // Answers with a reference to the parent question
                ];

                return flattenedItem;
            });

// Merge the flattened arrays into a single array
            const resultArray = [].concat(...flattenedArray);
            setData(resultArray);


        }
        fetchData()
            .catch(console.error)

    },[]);

    return (
        <ResponsiveBar
            data={data}
            theme={{
                // added
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },
                    legend: {
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
            }}
            keys={[ "maleCount", "femaleCount", ]}
            indexBy="parentQuestionId"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "nivo" }}
            tooltip={({ id, value, indexValue }) =>{ console.log(id); return(
                <div style={{ color: "black", background: "white", padding: "10px", borderRadius: "5px", zIndex:1000,
                    position: 'absolute', // Use absolute positioning
                    transform: id === 'femaleCount' ? 'translateX(20px)' : 'translateX(-100%)'}}>
                    <strong>Pitanje:</strong> {indexValue} {/* Assuming indexValue holds the question text */}
                    <br/>
                    <strong>{id == 'femaleCount' ? 'Žena' : 'Muškaraca'}:</strong> {value}
                </div>
            )}}
            defs={[
                {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                },
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                },
            ]}
            borderColor={{
                from: "color",
                modifiers: [["darker", "1.6"]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: isDashboard ? undefined : "Broj", // changed
                legendPosition: "middle",
                legendOffset: -40,
            }}
            enableLabel={true}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
            }}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: "right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                    items: [
                        {
                            id: "maleCount",
                            label: "Muskaraca",
                            color: colors.grey[100],
                        },
                        {
                            id: "femaleCount",
                            label: "Zena",
                            color: colors.grey[100],
                        }]
                },
            ]}
        />
    );
};

export default BarChart;
